import axios from 'axios'
export const http = axios.create({
	baseURL:process.env.VUE_APP_BASEURL,
		headers:{
		'content-type':'application/json',
		'Authorization':'Bearer ' + process.env.VUE_APP_TOKEN,
		}
});
export const httpdev=axios.create({
	baseURL:process.env.VUE_APP_BASEURL_DEV,
	headers:{
		'content-type':'application/json',
		'Authorization':'Bearer ' + process.env.VUE_APP_TOKEN,
	}
});
export const httpprod=axios.create({
	baseURL:process.env.VUE_APP_BASEURL_PROD,
	headers:{
		'content-type':'application/json',
		'Authorization':'Bearer ' + process.env.VUE_APP_TOKEN,
	}
});