import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import HomeComponent from './components/HomeComponent';
import MainComponent from './components/MainComponent';
import TestApiComponent from './components/TestApiComponent';
import ApiListsComponent from './components/ApiListsComponent';
import SpecificApiComponent from './components/SpecificApiComponent';

const routes=[
{
	path:'/',
	component:MainComponent,
	name:'main',
	children:[
		{
			path:'home',
			component:HomeComponent,
			name:'home',
		},
		{
			path:'api',
			component:TestApiComponent,
			name:'testapi',
			props:true,
		},
		{
			path:'apilists',
			component:ApiListsComponent,
			name:'apilists',
			children:[
			{
				path:':type',
				component:SpecificApiComponent,
				name:'specific.component',
			}
				
			]
		}
	]
},
];

const router=new VueRouter({routes});
router.beforeEach((to, from, next) => {
	//when user is logged in we had set the token value in local storage now we are fetching that token here
	// const token=localStorage.getItem('token') || null;
 //  window.axios.defaults.headers['Authorization'] = "Bearer "+token;
  next();
});
export default router