<template>
  <div class="home">
<h1>{{hello}}</h1>
  </div>
</template>

<script>
export default {
data(){
return{

}
},
computed: {
  hello() {
    return this.$store.state.common.hello;
  }
},
created(){

},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
