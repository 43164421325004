import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import testmode from './modules/testmode'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common: common,
    testmode: testmode,
  }
})
