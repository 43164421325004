const testmode = {
    namespaced: true,
	state: {
        testmode: false,
        frontUrl:process.env.VUE_APP_BASEURL,
	},

	mutations: {
		updateMode(state, data) {
            state.testmode=data;
            if(data){
               state.frontUrl=process.env.VUE_APP_BASEURL_PROD;
            }
            if(!data){
                state.frontUrl=process.env.VUE_APP_BASEURL;
            }
		}
	},

	actions: {
		changeMode(context, data) {
			// api call efhndjn
			context.commit('updateMode', data);
		}
	}
}
export default testmode;