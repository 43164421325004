<template>
  <div class="main">
    <div class="container-fluid" style="padding-left:0px">
      <div class="row">
        <div class="col-md-2">
          <div class="above-sidebar">
            <div class="text-center">
              <router-link :to="{name:'main'}" class="router-link"><h6 class="gadgetframe-text">{{headTitle}}</h6></router-link>
            </div>
          </div>
          <div class="sidebar shadow" style="background: white">
            <ul>
              <div v-for="menu in menus" v-bind:key="menu.id">
              <router-link  :to="{name:menu.route, params: { testdata:'' } }" class="router-link"><li :class="{'li-active':currentRoute==menu.route}">
                <i :class="menu.icon" class="icon"></i>
            {{menu.name}}</li></router-link>
            </div>
            <div>
              <hr>
              <li class="router-link">
                <p style="font-size: 16px;color: #a9a4a4;">Switch the testing mode</p>
              <label class="switch">
                <input  @change="switchChanged" type="checkbox">
                <span class="slider round"></span>
              </label>
              </li>
            </div>
            </ul>
          </div>
        </div>
        <div class="col-md-10">
          <div class="content-area">
           <router-view ></router-view>
         </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
data(){
return{
  //if testing mode false its developpent, true for production
  testingMode:false,
  currentRoute:'none',
  headTitle:"GadgetFrame Api",
  menus:[
  {id:1, icon:'fas fa-poll ',name:'Home',route:'home'},
  {id:2, icon:'far fa-poll ',name:'Api Lists',route:'apilists'},
  {id:3, icon:'far fa-smile-wink ',name:'Test Api',route:'testapi'},
  ],
};
},

computed: {
 testmode(){
   return this.$store.state.testmode.testmode;
 }
},

created(){

},
  watch:{
    $route(to, from){
      console.log(to,from);
      this.currentRoute=this.$route.name;
    }
  },

methods:{
  changeTestMode:function(){
     this.testingMode=!this.testingMode;
    this.$store.dispatch("testmode/changeMode",this.testingMode);
  },
  greet() {

    this.hello = "something" + new Date();
    // this.$store.dispatch("common/changeGreetings", "Hello " + new Date());
  },
  switchChanged:function(){
  //  localStorage.setItem('testingMode',this.testingMode);
   // const token=localStorage.getItem('token') || null;
   // this.$router.go(this.$router.currentRoute);
 this.testingMode=!this.testingMode;
    if(this.testingMode){
     alert('Switching to Production');
   }
   if(!this.testingMode){
     alert('Switching to Development');
   }
    this.$store.dispatch("testmode/changeMode",this.testingMode);
  },
  getChildRouteName:function(){

  }

},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon{
  margin-right:5px;
}
.content-area{
  padding-top:30px;
}
.router-link{
  text-decoration: none;
}
.above-sidebar{
  width:100%;
  background:#149edc;
}
.gadgetframe-text{
  color:white;
  padding:20px;
  margin:0px;
  font-size:19px;
}

.sidebar{
  width:100%;
  height: 100vh;
  padding-top:20px;
}
.sidebar ul{
  padding:0px;
}
.sidebar ul li{
  color:#6f6969;
  list-style: none;
  padding: 8px 20px 8px 20px;
}
.sidebar ul li:hover{
  background:#f3f3f3;
  border-right:5px solid #cbcdce;
  color:black;
  cursor: pointer;
}
.li-active{
  background:#f3f3f3;
  border-right:5px solid #cbcdce;
  color:black;
  cursor: pointer;
}
/*--switch toggle--*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
