<template>
  <div class="specific-api-component">
    <div class="ul-wrapper shadow">
      <h5 class="head-text">
        All the
        <span style="color: #dc2e2e">{{ $route.params.type }}</span> Api:
      </h5>

      <div v-if="apisblogs">
        <ul class="ul-apis">
          <li v-for="api in apis_blogs" v-bind:key="api.id">
            <h5>{{ api.heading }}</h5>
            <router-link
              :to="{ name: 'testapi', params: { testdata: api.link } }"
            >
              {{ frontUrl }}/{{ api.api }}
            </router-link>
            &nbsp;
            <span style="margin-left: 20px"
              ><br />
              <span style="color: rgb(165 139 139)">{{ api.info }}</span
              ><br /><br />
            </span>
          </li>
        </ul>
      </div>

      <div v-if="apisproducts">
        <ul class="ul-apis">
          <li v-for="api in apis_products" v-bind:key="api.id">
            <h5>{{ api.heading }}</h5>
            <router-link
              :to="{ name: 'testapi', params: { testdata: api.link } }"
            >
              {{ frontUrl }}/{{ api.api }}
            </router-link>
            &nbsp;
            <span style="margin-left: 20px"
              ><br />
              <span style="color: rgb(165 139 139)">{{ api.info }}</span
              ><br /><br
            /></span>
          </li>
        </ul>
      </div>

      <div v-if="apisbrands">
        <ul class="ul-apis">
          <li v-for="api in apis_brands" v-bind:key="api.id">
            <h5>{{ api.heading }}</h5>
            <router-link
              :to="{ name: 'testapi', params: { testdata: api.link } }"
            >
              {{ frontUrl }}/{{ api.api }}
            </router-link>
            &nbsp;<span style="margin-left: 20px"
              ><br />
              <span style="color: rgb(165 139 139)">{{ api.info }}</span
              ><br /><br
            /></span>
          </li>
        </ul>
      </div>

      <div v-if="apisstores">
        <ul class="ul-apis">
          <li v-for="api in apis_stores" v-bind:key="api.id">
            <h5>{{ api.heading }}</h5>
            <router-link
              :to="{ name: 'testapi', params: { testdata: api.link } }"
            >
              {{ frontUrl }}/{{ api.api }}
            </router-link>
            &nbsp;
            <span style="margin-left: 20px"
              ><br />
              <span style="color: rgb(165 139 139)">{{ api.info }}</span
              ><br /><br
            /></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    testmode() {
      return this.$store.state.testmode.testmode;
    },
    frontUrl() {
      return this.$store.state.testmode.frontUrl;
    },
  },
  data() {
    return {
      frontApi: "",
      routeParams: this.$route.params.type,
      apisblogs: false,
      apisproducts: false,
      apisbrands: false,
      apisstores: false,
      apis_blogs: [
        {
          id: "1",
          heading: "GET the blogs written by user",
          api: "blog/by/{user}",
          link: "blog/by/{put-user-here}",
          info:
            "Blogs written by particular person replace {user} by editor or writer name",
        },
        {
          id: "2",
          heading: "GET the blog of by slug",
          api: "blog/{slug}",
          link: "blog/{put-slug-here}",
          info: "It returns single blog of slug entered",
        },
        {
          id: "3",
          heading: "GET the blogs by category",
          api: "blog/category/{slug}",
          link: "blog/category/{put-slug-here}",
          info: "It returns blogs of specified category",
        },
        {
          id: "4",
          heading: "GET the blogs by tag",
          api: "blog/tag/{slug}",
          link: "blog/tag/{put-slug-here}",
          info: "It returns blogs of specified tag",
        },
        {
          id: "5",
          heading: "GET the latest two blogs",
          api: "blogs/blog-of-day",
          link: "blogs/blog-of-day",
          info: "It returns latest 2 blogs",
        },
        {
          id: "6",
          heading: "NOT WORKING",
          api: "top/blogs/type",
          link: "top/blogs/type",
          info: "It is not working",
        },
        {
          id: "7",
          heading: "GET the blog by slug but no prefix v1",
          api: "blog/{slug}",
          link: "blog/{put-slug-here}",
          info:
            "It returns single blog detail of slug entered but no prefix v1 in api link",
        },
        {
          id: "8",
          heading: "GET latest blogs list",
          api: "blogs",
          link: "blogs",
          info: "Returns 13 latest Blogs with pagination link",
        },
      ],

      apis_products: [
        {
          id: "1",
          heading: "GET latest products by id",
          api: "{id}/latest-products",
          link: "{put-id-here}/latest-products",
          info:
            "Returns products list of specified category id, returns 16 records",
        },
        {
          id: "2",
          heading: "GET popular products by category id",
          api: "{id}/popular-products",
          link: "{put-id-here}/popular-products",
          info:
            "Returns products list of specified category id descending on views, returns 16 records",
        },
        {
          id: "3",
          heading: "GET nepali products by category id",
          api: "{id}/nepali-products",
          link: "{put-id-here}/nepali-products",
          info:
            "Returns 16 nepali products based on availability order by rank descending. rank is calculated as totalviews/days of product created",
        },
        {
          id: "4",
          heading: "GET product detail by brand and model",
          api: "brand/{brand}/{model}",
          link: "brand/{brand-name-here}/{model-name-here}",
          info: "Returns detail of model of specified brand",
        },
        {
          id: "5",
          heading: "COMPARE PRODUCTS",
          api: "compare",
          link: "compare",
          info: "",
        },
        {
          id: "6",
          heading: "PHONEFINDER",
          api: "phonefinder",
          link: "phonefinder",
          info: "",
        },
        {
          id: "7",
          heading: "GET products list",
          api: "products",
          link: "products",
          info: "Returns 16 produts with pagination links",
        },
        {
          id: "8",
          heading: "GET product detail by id",
          api: "products/{id}",
          link: "products/{product-id-here}",
          info: "Returns product detail of specified id",
        },
      ],

      apis_brands: [
        {
          id: "1",
          heading: "GET brands list by category",
          api: "{slug}/brands",
          link: "{category-slug-here}/brands",
          info: "Returns all the brands of category entered",
        },
        {
          id: "2",
          heading: "GET brand detail by category and brand name",
          api: "{cat}/brand/{name}",
          link: "{cat-here}/brand/{put-name-here}",
          info:
            "it returns brand detail of specified category eg: {cat} might be mobile or laptop and {name} might be brand name in the form of slug, expects to return with its associated products but it doesnot",
        },
        {
          id: "3",
          heading: "GET products by category and brandname",
          api: "{cat}/latest-model/{name}",
          link: "{cat-here}/latest-model/{put-name-here}",
          info:
            "Returns all the products of specified brand name eg:{name} could be huawei {cat} could be mobile or other category, return 21 records with pagination links",
        },
        {
          id: "4",
          heading: "GET products by categroy and brandname",
          api: "{cat}/popular-model/{name}",
          link: "{category-here}/popular-model/{put-name-here}",
          info:
            "Returns all the products of specified brand name eg:{name} might be huawei {cat} might be mobile or other category, return 21 records with pagination links order by view descending",
        },
        {
          id: "5",
          heading: "GET latest brands",
          api: "brands",
          link: "brands",
          info: "Returns 13 latest Brands with pagination link",
        },
        {
          id: "6",
          heading: "GET brand detail by id",
          api: "brands/{id}",
          link: "brands/{brand-id-here}",
          info: "Returns brand detail of specified id",
        },
      ],

      apis_stores: [
        {
          id: "1",
          heading: "GET store detail by slug",
          api: "store/{slug}",
          link: "store/{slug-here}",
          info: "Returns store detail of specified store slug",
        },
        {
          id: "2",
          heading: "GET products of stores by category and storename",
          api: "{category}/store/{name}/products",
          link: "{category}/store/{name}/products",
          info:
            "Returns products list of specified stores_name and category, here {name} is the storename as slug, returns 16 records",
        },
        {
          id: "3",
          heading: "NOT WORKING",
          api: "stores",
          link: "stores",
          info: "It is not working",
        },
        {
          id: "4",
          heading: "GET store detail by id",
          api: "stores/{store}",
          link: "stores/{store_id}",
          info: "Returns store detail of specified store id",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.showData(this.routeParams);
    this.watchTestMode();
  },
  watch: {
    "$route.params.type": function () {
      this.hideApisList();
      this.routeParams = this.$route.params.type;
      this.showData(this.routeParams);
    },
    testmode() {
      //   this.jsonrequest.baseUrl = this.frontUrl;
      //   for (var i = 0; i < this.apis_blogs.length; i++) {
      //     this.apis_blogs[i].apiFront = this.frontUrl;
      //   }
    },
  },
  methods: {
    watchTestMode: function () {
      for (var i = 0; i < this.apis_blogs.length; i++) {
        this.apis_blogs[i].apiFront = this.frontUrl;
      }
    },
    hideApisList: function () {
      this.apisblogs = false;
      this.apisproducts = false;
      this.apisbrands = false;
      this.apisstores = false;
    },

    showData: function ($params) {
      if ($params == "blogs") {
        this.hideApisList();
        this.apisblogs = true;
      }
      if ($params == "products") {
        this.hideApisList();
        this.apisproducts = true;
      }
      if ($params == "brands") {
        this.hideApisList();
        this.apisbrands = true;
      }
      if ($params == "stores") {
        this.hideApisList();
        this.apisstores = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ul-wrapper {
  margin-top: 50px;
  padding: 30px;
}
.ul-apis {
  margin-top: 30px;
}
.ul-apis li {
  padding-top: 10px;
  list-style: none;
}
.head-text {
  padding-bottom: 10px;
  border-bottom: 1px solid #d4cdcd;
}
</style>
