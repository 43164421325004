<template>
  <div class="api-lists-component">
  <router-link  :to="{name:'specific.component',params: { type: 'blogs' }}"><span class="custom-box shadow"  :class="{boxActive:routeParams=='blogs'}">Blogs Api</span></router-link>
  <router-link  :to="{name:'specific.component',params: { type: 'brands' }}"><span class="custom-box shadow"  :class="{boxActive:routeParams=='brands'}">Brands Api</span></router-link>
  <router-link  :to="{name:'specific.component',params: { type: 'products' }}"><span class="custom-box shadow"  :class="{boxActive:routeParams=='products'}">Products Api</span></router-link>

  <router-link  :to="{name:'specific.component',params: { type: 'stores' }}"><span class="custom-box shadow"  :class="{boxActive:routeParams=='stores'}">Storese Api</span></router-link>

  <router-view></router-view>
 </div>
</template>

<script>
export default {
data(){
	return{
		to:'',
		from:'',
		currentRoute:'',
		blogActive:false,
		brandActive:false,
		productActive:false,
		storesActive:false,
		routeParams:'',
	};
},
watch:{
		$route(to, from){
			console.log(to,from);
			this.routeParams=this.$route.params.type;
			this.currentRoute=this.$route.name;
			(this.currentRoute=='apilists')?this.$router.push({name:'specific.component',params: { type: 'blogs' }}):'';
    }
},
created(){
	console.log('created method');
	this.$router.push({name:'specific.component',params: { type: 'blogs' }});
},
methods:{

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.custom-box{
	padding:10px;
	margin-left:10px;
}
.boxActive{
	background: #e4ecec;
}

</style>
