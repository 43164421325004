const common = {
	namespaced: true,
	state: {
		hello: "Hello World!"
	},

	mutations: {
		updateHello(state, data) {
			console.log(data);
			state.hello = data;
		}
	},

	actions: {
		changeGreetings(context, data) {
			// api call efhndjn
			context.commit('updateHello', data);
		}
	}
}
export default common;