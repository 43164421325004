<template>
  <div class="all-api-component" style="padding: 20px; background: white">
    <div class="row">
      <div class="col-md-12">
        <!-- <label for="cars">Choose a mode:</label>
        <select
          @change="onSelectChange($event)"
          class="form-control"
          name="cars"
          id="cars"
          style="margin-bottom: 20px"
        >
          <option value="default">Default</option>
          <option value="dev">Dev</option>
          <option value="production">Production</option>
        </select> -->
        <div class="row">
          <div class="col-md-6">
            <label for="api_type">Choose Api Type</label>
            <select
              @change="onSelectChangeApiType($event)"
              class="form-control"
              style="margin-bottom: 20px"
            >
              <option value="choose_api_type">Choose Type</option>
              <option value="blogs">Blogs</option>
              <option value="brands">Brands</option>
              <option value="products">Produts</option>
              <option value="stores">Stores</option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="api">Select Api</label>
            <select
              v-if="apisdefault"
              class="form-control"
              style="margin-bottom: 20px"
            >
              <option>Choose Api</option>
            </select>
            <select
              @change="selectApi($event, 'blogs')"
              class="form-control"
              v-if="apisblogs"
              style="margin-bottom: 20px"
            >
              <option value="choose_api">Choose Api</option>
              <option
                v-for="api in apis_blogs"
                v-bind:key="api.id"
                v-bind:value="api.id"
              >
                {{ api.link }}
              </option>
            </select>
            <select
              @change="selectApi($event, 'products')"
              class="form-control"
              v-if="apisproducts"
              style="margin-bottom: 20px"
            >
              <option value="choose_api">Choose Api</option>
              <option
                v-for="api in apis_products"
                v-bind:key="api.id"
                :value="api.id"
              >
                {{ api.link }}
              </option>
            </select>
            <select
              @change="selectApi($event, 'brands')"
              class="form-control"
              v-if="apisbrands"
              style="margin-bottom: 20px"
            >
              <option value="choose_api">Choose Api</option>
              <option
                v-for="api in apis_brands"
                v-bind:key="api.id"
                :value="api.id"
              >
                {{ api.link }}
              </option>
            </select>
            <select
              @change="selectApi($event, 'stores')"
              class="form-control"
              v-if="apisstores"
              style="margin-bottom: 20px"
            >
              <option value="choose_api">Choose Api</option>
              <option
                v-for="api in apis_stores"
                v-bind:key="api.id"
                :value="api.id"
              >
                {{ api.link }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="api_info_toggle" class="api_info">{{ api_info }}</div>

        <form @submit.prevent="callapi">
          <div class="row">
            <div class="col-md-12">
              <input
                type="text"
                v-model="frontUrl"
                class="baseurl_textbox form-control"
                style="float: left"
              />
              <input
                type="text"
                v-model="jsonrequest.url"
                class="url_textbox form-control"
                style="float: left"
              />
              <button
                type="submit"
                class="button_ok btn btn-success shadow"
                style="float:left;margin-left:10px;border-radius:50px"
              ><i class="fas fa-paper-plane"></i>&nbsp;Call this api</button>
            </div>
          </div>
          <br />
        </form>
        <!-- <div>
          <p class="btn btn-sm btn-info" @click="enableRequestDataForm">
            {{ infotext }}
          </p>
        </div> -->
        <div v-if="requestwithData" class="shadow-sm" style="background-color:whitesmoke;padding:20px">
          <div class="row">
            <div class="col-md-12">
                   <input type="text" placeholder="key" class="form-control" v-model="key" style="float:left;width:250px"/>
          <input
            type="text"
            placeholder="value"
            v-model="value"
            style="margin-left: 10px;float:left;width:250px"
            class="form-control"
          />
          <button
            class="btn btn-primary"
            @click="attach"
            style="margin-left: 10px;float:left"
          >
            <i class="fas fa-plus-circle"></i>&nbsp;Attach this data
          </button>
          <button v-if="enableDetachButton==true" @click="detach" class="btn btn-danger" style="margin-left:10px">
            <i class="fas fa-minus-square"></i>&nbsp;&nbsp;Detach
          </button>
            </div>
          </div>
     
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="json-request-title">REQUEST DATA</div>
        <div class="json-request">
          <pre>{{ jsonrequest }}</pre>
        </div>
      </div>
      <div class="col-md-6">
        <div class="json-response-title">RESPONSE DATA</div>
        <div class="json-response">
          <div class="text-center" v-if="jsonLoading">
            <div class="spinner-border text-center" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <pre>{{ jsonresponse }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, httpprod } from "../http";
export default {
  computed: {
    testmode() {
      return this.$store.state.testmode.testmode;
    },
    frontUrl() {
      return this.$store.state.testmode.frontUrl;
    },
  },
  watch: {
    testmode() {
      this.jsonrequest.baseUrl = this.frontUrl;
    },
  },
  props: ["testdata"],
  data() {
    return {
      enableDetachButton:false,
      keyHistory:[],
      testingMode: false,
      api_info_toggle: false,
      api_info: "",
      api_link: "",
      apisdefault: true,
      apisblogs: false,
      apisstores: false,
      apisbrands: false,
      apisproducts: false,
      mode: "",
      apitype: "",
      infotext: "Send data with request",
      requestwithData: true,
      key: "",
      value: "",
      bodydata: "",
      base_url: process.env.VUE_APP_BASEURL,
      jsonLoading: false,
      jsonresponse: "",
      jsonrequest: {
        method: "GET",
        baseUrl: process.env.VUE_APP_BASEURL,
        url: "",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + process.env.VUE_APP_TOKEN,
        },
        body: {},
      },
      token: "",
      apis_blogs: [
        {
          id: "1",
          heading: "GET the blogs written by user",
          api: process.env.VUE_APP_BASEURL + "blog/by/{user}",
          link: "blog/by/{put-user-here}",
          info:
            "Blogs written by particular person replace {user} by editor or writer name",
        },
        {
          id: "2",
          heading: "GET the blog of by slug",
          api: process.env.VUE_APP_BASEURL + "blog/{slug}",
          link: "blog/{put-slug-here}",
          info: "It returns single blog of slug entered",
        },
        {
          id: "3",
          heading: "GET the blogs by category",
          api: process.env.VUE_APP_BASEURL + "blog/category/{slug}",
          link: "blog/category/{put-slug-here}",
          info: "It returns blogs of specified category",
        },
        {
          id: "4",
          heading: "GET the blogs by tag",
          api: process.env.VUE_APP_BASEURL + "blog/tag/{slug}",
          link: "blog/tag/{put-slug-here}",
          info: "It returns blogs of specified tag",
        },
        {
          id: "5",
          heading: "GET the latest two blogs",
          api: process.env.VUE_APP_BASEURL + "blogs/blog-of-day",
          link: "blogs/blog-of-day",
          info: "It returns latest 2 blogs",
        },
        {
          id: "6",
          heading: "NOT WORKING",
          api: process.env.VUE_APP_NOPREFIX + "top/blogs/type",
          link: "top/blogs/type",
          info: "It is not working",
        },
        {
          id: "7",
          heading: "GET the blog by slug but no prefix v1",
          api: process.env.VUE_APP_NOPREFIX + "blog/{slug}",
          link: "blog/{put-slug-here}",
          info:
            "It returns single blog detail of slug entered but no prefix v1 in api link",
        },
        {
          id: "8",
          heading: "GET latest blogs list",
          api: process.env.VUE_APP_BASEURL + "blogs",
          link: "blogs",
          info: "Returns 13 latest Blogs with pagination link",
        },
      ],
      apis_products: [
        {
          id: "1",
          heading: "GET latest products by id",
          api: process.env.VUE_APP_BASEURL + "{id}/latest-products",
          link: "{put-id-here}/latest-products",
          info:
            "Returns products list of specified category id, returns 16 records",
        },
        {
          id: "2",
          heading: "GET popular products by category id",
          api: process.env.VUE_APP_BASEURL + "{id}/popular-products",
          link: "{put-id-here}/popular-products",
          info:
            "Returns products list of specified category id descending on views, returns 16 records",
        },
        {
          id: "3",
          heading: "GET nepali products by category id",
          api: process.env.VUE_APP_BASEURL + "{id}/nepali-products",
          link: "{put-id-here}/nepali-products",
          info:
            "Returns 16 nepali products based on availability order by rank descending. rank is calculated as totalviews/days of product created",
        },
        {
          id: "4",
          heading: "GET product detail by brand and model",
          api: process.env.VUE_APP_BASEURL + "brand/{brand}/{model}",
          link: "brand/{brand-name-here}/{model-name-here}",
          info: "Returns detail of model of specified brand",
        },
        {
          id: "5",
          heading: "COMPARE PRODUCTS",
          api: process.env.VUE_APP_BASEURL + "compare",
          link: "compare",
          info: "",
        },
        {
          id: "6",
          heading: "PHONEFINDER",
          api: process.env.VUE_APP_BASEURL + "phonefinder",
          link: "phonefinder",
          info: "",
        },
        {
          id: "7",
          heading: "GET products list",
          api: process.env.VUE_APP_BASEURL + "products",
          link: "products",
          info: "Returns 16 produts with pagination links",
        },
        {
          id: "8",
          heading: "GET product detail by id",
          api: process.env.VUE_APP_BASEURL + "products/{id}",
          link: "products/{product-id-here}",
          info: "Returns product detail of specified id",
        },
      ],
      apis_brands: [
        {
          id: "1",
          heading: "GET brands list by category",
          api: process.env.VUE_APP_BASEURL + "{slug}/brands",
          link: "{category-slug-here}/brands",
          info: "Returns all the brands of category entered",
        },
        {
          id: "2",
          heading: "GET brand detail by category and brand name",
          api: process.env.VUE_APP_BASEURL + "{cat}/brand/{name}",
          link: "{cat-here}/brand/{put-name-here}",
          info:
            "it returns brand detail of specified category eg: {cat} might be mobile or laptop and {name} might be brand name in the form of slug, expects to return with its associated products but it doesnot",
        },
        {
          id: "3",
          heading: "GET products by category and brandname",
          api: process.env.VUE_APP_BASEURL + "{cat}/latest-model/{name}",
          link: "{cat-here}/latest-model/{put-name-here}",
          info:
            "Returns all the products of specified brand name eg:{name} could be huawei {cat} could be mobile or other category, return 21 records with pagination links",
        },
        {
          id: "4",
          heading: "GET products by categroy and brandname",
          api: process.env.VUE_APP_BASEURL + "{cat}/popular-model/{name}",
          link: "{category-here}/popular-model/{put-name-here}",
          info:
            "Returns all the products of specified brand name eg:{name} might be huawei {cat} might be mobile or other category, return 21 records with pagination links order by view descending",
        },
        {
          id: "5",
          heading: "GET latest brands",
          api: process.env.VUE_APP_BASEURL + "brands",
          link: "brands",
          info: "Returns 13 latest Brands with pagination link",
        },
        {
          id: "6",
          heading: "GET brand detail by id",
          api: process.env.VUE_APP_BASEURL + "brands/{id}",
          link: "brands/{brand-id-here}",
          info: "Returns brand detail of specified id",
        },
      ],
      apis_stores: [
        {
          id: "1",
          heading: "GET store detail by slug",
          api: process.env.VUE_APP_BASEURL + "store/{slug}",
          link: "store/{slug-here}",
          info: "Returns store detail of specified store slug",
        },
        {
          id: "2",
          heading: "GET products of stores by category and storename",
          api: process.env.VUE_APP_BASEURL + "{category}/store/{name}/products",
          link: "{category}/store/{name}/products",
          info:
            "Returns products list of specified stores_name and category, here {name} is the storename as slug, returns 16 records",
        },
        {
          id: "3",
          heading: "NOT WORKING",
          api: process.env.VUE_APP_BASEURL + "stores",
          link: "stores",
          info: "It is not working",
        },
        {
          id: "4",
          heading: "GET store detail by id",
          api: process.env.VUE_APP_BASEURL + "stores/{store}",
          link: "stores/{store_id}",
          info: "Returns store detail of specified store id",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.jsonrequest.url = this.$props.testdata;
    this.jsonrequest.baseUrl = this.frontUrl;
  },
  methods: {
    selectApi: function (event, type) {
      let api_id = event.target.value;
      let data;
      if (type == "blogs") {
        data = this.apis_blogs.find((blog) => blog.id === api_id);
      }
      if (type == "stores") {
        data = this.apis_stores.find((store) => store.id === api_id);
      }
      if (type == "products") {
        data = this.apis_products.find((product) => product.id === api_id);
      }
      if (type == "brands") {
        data = this.apis_brands.find((brand) => brand.id === api_id);
      }
      if (data) {
        this.api_info_toggle = true;
        this.api_info = data.info;
        this.jsonrequest.url = data.link;
      }
      // this.jsonrequest.url=(event.target.value=="choose_api")?'':event.target.value;
    },
    onSelectChangeApiType: function (event) {
      this.apitype = event.target.value;
      if (this.apitype == "choose_api_type") {
        this.makeAllApiFalse();
        this.apisdefault = true;
      }
      if (this.apitype == "blogs") {
        this.makeAllApiFalse();
        this.apisblogs = true;
      }
      if (this.apitype == "products") {
        this.makeAllApiFalse();
        this.apisproducts = true;
      }
      if (this.apitype == "brands") {
        this.makeAllApiFalse();
        this.apisbrands = true;
      }
      if (this.apitype == "stores") {
        this.makeAllApiFalse();
        this.apisstores = true;
      }
    },
    makeAllApiFalse: function () {
      this.apisblogs = false;
      this.apisproducts = false;
      this.apisbrands = false;
      this.apisstores = false;
      this.apisdefault = false;
      this.api_info_toggle = false;
    },
    onSelectChange: function (event) {
      this.mode = event.target.value;
      if (this.mode == "dev") {
        this.jsonrequest.baseUrl = process.env.VUE_APP_BASEURL_DEV;
      }
      if (this.mode == "production") {
        this.jsonrequest.baseUrl = process.env.VUE_APP_BASEURL_PROD;
      }
      if (this.mode == "default") {
        this.jsonrequest.baseUrl = process.env.VUE_APP_BASEURL;
      }
    },
    enableRequestDataForm: function () {
      this.requestwithData = !this.requestwithData;
    },
    detach:function(){
      let lengthKey=this.keyHistory.length;
      let keyToRemove=this.keyHistory[lengthKey-1];
      delete this.jsonrequest.body[keyToRemove];
      var newdata={ ...this.jsonrequest.body};
      this.jsonrequest.body=newdata;
      this.keyHistory.splice(lengthKey-1, 1);
      if(this.keyHistory.length==0){
        this.enableDetachButton=false;
      }
    },
    attach: function () {
      this.keyHistory.push(this.key);
      var data = "{" + '"' + this.key + '"' + ":" + '"' + this.value + '"}';
      var merged = { ...this.jsonrequest.body, ...JSON.parse(data) };
      this.jsonrequest.body = merged;
      this.key = "";
      this.value = "";
      this.enableDetachButton=true;
    },
    callapi: function () {
      this.jsonresponse = "";
      this.jsonLoading = true;
      var usedHttp = "";
      if (!this.testmode) {
        usedHttp = http;
      }
      if (this.testmode) {
        usedHttp = httpprod;
      }
      usedHttp
        .get(this.jsonrequest.url, {
          body: this.jsonrequest.body,
        })
        .then((res) => {
          // console.log(res);
          this.jsonresponse = res;
          this.jsonLoading = false;
          // this.jsonrequest=res.config;
        })
        .catch((err) => {
          console.log(err);
          this.jsonresponse = err;
          this.jsonLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.api_info {
  padding: 10px;
  color: white;
  background: #149edc;
  margin: 10px 0px 30px 0px;
}
.custom-box {
  padding: 30px;
}
.json-response {
  padding: 20px;
  background: #f1efe5;
  border: 2px solid #d4c4c4;
  height: 500px;
  overflow: scroll;
}
.json-request {
  padding: 20px;
  background: #f1efe5;
  border: 2px solid #d4c4c4;
  height: 500px;
  overflow: scroll;
}
.json-request-title {
  padding: 3px;
  text-align: center;
  color: white;
  margin-top: 20px;
  width: 100%;
  background: #149edc;
}
.json-response-title {
  padding: 3px;
  text-align: center;
  color: white;
  margin-top: 20px;
  width: 100%;
  background: #149edc;
}
.baseurl_textbox {
  border: unset;
  background: #efefef;
  width: 270px;
}
.url_textbox {
  margin-left: 5px;
  width: 50%;
}
.button_ok {
  margin-left: 5px;
}
</style>
